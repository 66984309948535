import React from "react";
import CardItem from "../CardItem";
import "./General.css";
import { traveldata } from "./TravelData";

export function TravelBasedOnLocation(historyParams) {
  console.log(historyParams);
  const location = historyParams.match.params.locationtype;

  const filteredTravel = traveldata.filter(
    (travel) => travel.locationType === location
  );
  return (
    <div className="overall__page">
      <div className="container">
        <div className="wrapper">
          <ul className="button">
            {filteredTravel.length < 1 ? (
              <div>Coming Soon!</div>
            ) : (
              filteredTravel.map((travel) => (
                <CardItem
                  key={travel.id}
                  src={"../../images" + travel.img}
                  text={travel.title}
                  path={"/location-name/" + travel.id}
                  Label="Blog"
                ></CardItem>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
