import React from "react";

export function imageHolder(imageSource, imageText) {
    return (
        <div key={imageSource}>
            <img
                className="travel-image"
                src={imageSource}
                alt={imageText}
            ></img>
            <div className="travel-caption">
                <p>{imageText}</p>
            </div>
        </div>
    )
}