import React from "react";
import { Link } from "react-router-dom";
import { imageHolder } from "../ImageHolder";
import "./General.css";
import { traveldata } from "./TravelData";
import "./TravelNames.css";
/*import "./Travel.css"*/

export function travelNames(names) {
  const traveltitle = names.match.params.name;

  const uniquelocation = traveldata.find((onelocation) => {
    return onelocation.id.toString() === traveltitle;
  });


  return (
    !uniquelocation ?
    <div >
      
    <Link to="/Travel">
    <h1>
    How did you get here?
      </h1> 
    </Link>
      
      </div>
    :(
    <div className="above">
      <h1>{uniquelocation.title}</h1>
      <h2>{uniquelocation.description}</h2>
        {uniquelocation.days.map(day => {
          return (
             < div className="day-info" key={day.info}>
           <h3>{day.header}</h3>
           <p>{day.info}</p>
           {
             day.images ? day.images.map(image => imageHolder(image.src,image.caption))            
             :imageHolder(day.image,day.imageCaption)}
      </div>)
        }) }
    </div>)
  );
}
