export const recipePath = {
  chinese: "chinese-recipe",
  japanese: "japanese-recipes",
  indian: "indian-recipes",
  korean: "korean-recipes",
  thai: "thai-recipes",
};

export const recipedata = [
  {
    id: 1,
    img: "mincedBeefandCelery.jpg",
    title: "Chinese Stir Fried Beef Mince with Celery",
    original: "This recipe is taken from a website we came across and can be found ",
    originallink: "http://cookingsimplechinesefoodathome.com/2012/05/127-a-simple-healthy-chinese-recipe-stir-fry-beef-mince-with-celery.html",
    description: "Very quick and easy recipe that is surprisingly delicious!",
    ingredients: [
      "Beef mince - ~300g",
      "Celery - 1 bunch",
      "Light soy sauce - 4 tbsp",
      "Cooking wine - 4 tsp",
      "Corn flour - 2 tbsp",
      "Spring Onion - 3/4",
      "Ginger - Thumb sized piece",
      "Oil - 3 tbsp",
      "Rice",
    ],
    instructions: [
      "Chop the celery into around 3cm long strips.",
      "Mix the beef mince with the light soy sauce, cooking wine, and corn flour.",
      "Let all sit for 20 mins.",
      "Chop half of spring onion and all of the ginger into small pieces.",
      "Add the oil to a heated wok.",
      "When the oil is getting hot, add in chopped ginger and spring onion. Stir fry.",
      "Add in beef mince. Stir fry mince in the wok (be careful not to overcook it). Put everything on a plate.",
      "Leave around 1½ tablespoon of oil in the wok, add in chopped celery, stir fry quickly.",
      "Add the stir-fried beef mince back to the wok, stir fry it with celery together.",
      "Taste. If needed, add 1 Tablespoon extra of both light soy sauce and cooking wine",
    ],
    notes: [
      "We have modified the amounts of the ingredients as the original calls for a very small amount of beef and celery.",
      "Serve with rice.",
    ],
    cuisineType: recipePath.chinese,

  },
  {
    id: 2,
    img: "chinese.jpg",
    title: "Mapo Tofu",
    original: "This recipe is taken from a website we came across and can be found ",
    originallink: "https://www.chinasichuanfood.com/mapo-tofu-recipe/",
    description: "Originating from Szechuan, this dish is spicy and the pepper will make your lips tingle!",
    ingredients: [
      "Silken tofu - 450g",
      "Minced beef or pork - ~200g",
      "Sesame seed oil - ½ tbsp",
      "Doubanjiang (roughly chopped) - 1+½ tbsp",
      "Fermented black beans (roughly chopped) - 2 tbsp",
      "Pepper - 1 tbsp",
      "Salt - ½ tsp",
      "Sichuan pepper - ½ tbsp",
      "Light soy sauce - 1 tbsp",
      "Water or broth - 400ml",
      "Oil - 2 tbsp",
      "Spring onions - 6",
      "Garlic cloves (finely chopped) - 2",
      "Ginger (finely chopped) - thumb size",
      "Sugar - 1 tsp",
      "Water - 2+½ tbsp ",
      "Corn flour - 1 tbsp",
      "Rice, to serve",
    ],
    instructions: [
      "Add a small pinch of salt and sesame oil to minced beef. Mix well and set aside.",
      "Mix 1 tablespoon of corn flour with 2 and ½ tablespoons of water in a small bowl to make water starch.",
      "Cut tofu into square cubes (around 2cms). Bring a large amount of water to a boil and then add a pinch of salt. Slide the tofu in and cook for 1 minute. Move out and drain.",
      "Get a wok and heat up around 2 tablespoons of oil, fry the minced meat until crispy. Transfer out beef out and leave the oil in.",
      "Fry doubanjiang for 1 minute over slow fire and then add garlic, 2 chopped spring onions, ginger and fermented black beans to cook for 30 seconds until aroma. Then mix pepper flakes in.",
      "Add water to the seasonings and bring to boil over high fire. Gently slide the tofu cubes. Add light soy sauce and beef.Slow the heat after boiling and then simmer for 6-8 minutes. Then add the 4 remaining chopped spring onions.",
      "Stir the water starch and then pour half of the mixture to the simmering pot. Wait for around 30 seconds and then pour the other half. You can slightly taste the tofu and add pinch of salt if not salty enough. By the way, if you feel it is too spicy, add some sugar can milder the taste. But be carefully as the broth is very hot at this point.",
      "Transfer out when almost all the seasonings stick to tofu cubes. Sprinkle Szechuan peppercorn powder (to taste)and chopped garlic greens if using.",
      "Serve with rice.",
    ],
    notes: [
      "We generally do at least one side dish of vegetable to go with this dish.",
    ],
    cuisineType: recipePath.chinese,

  },
  {
    id: 3,
    img: "koreanSeafoodStew.jpg",
    title: "Seafood & Silken Tofu Stew (sundubu jigae)",
    original: "This recipe is taken from the recipe book Our Korean Kitchen which can be found ",
    originallink: "https://www.amazon.co.uk/Our-Korean-Kitchen-Jordan-Bourke/dp/0297609718/ref=sr_1_1?crid=XBR9K2KK65H1&dchild=1&keywords=our+korean+kitchen&qid=1602609425&sprefix=our+korean+%2Caps%2C157&sr=8-1",
    description: "Incredibly tasty and warming stew. Easily one of my favourite recipes.",
    ingredients: [
      "Gochuguru red pepper powder - 1 tbsp",
      "Garlic cloves, crushed - 2",
      "Soy Sauce - 1 tbsp",
      "Sesame seed oil - 1 tbsp",
      "Spring onions - 2. 1 finely chopped, 1 finely sliced. ",
      "Silken tofu - 300g",
      "Beef stock - 350ml",
      "Clams - 100g (optional)",
      "Prawns - 100g",
      "Red Chilli - 1",
      "Eggs - 2",
      "Salt and pepper to taste",
      "Rice, to serve",
    ],
    instructions: [
      "In a bowl, combine the gochuguru powder, garlic, soy sauce, sesame seed oil and the finely chopped spring onion.",
      "In a pan, add the tofu, stock and clams (if using).",
      "Break up the silken tofu slightly into smaller chunks.",
      "Add the prawns, gochuguru mixture and chilli.",
      "Simmer for 5 minutes and taste for seasoning, if necessary.",
      "Stir in the sliced spring onion and eggs. Stir to distribute and remove from the heat.",
      "Serve.",
    ],
    notes: [
      "We generally do at least one side dish of vegetable to go with this dish.",
      "Increase the Gochuguru if you like it a little spicier."
    ],
    cuisineType: recipePath.korean,
  },
  {
    id: 4,
    img: "cucumberKimchi.jpg",
    title: "Cucumber Kimchi",
    original: "This recipe is taken from the Future Neighbor YouTube channel which can be found ",
    originallink: "https://futuredish.com/spicy-korean-cucumber-salad/",
    description: "Spicy Korean Cucumber Salad (pronouced ‘oh-e-moo-chim’) is the perfect beginner’s recipe to Korean cooking. It requires little prep work and uses common pantry sauces.",
    ingredients: [
      "Cucumber - 1",
      "Vinegar - 3 tbsp",
      "Korean red chilli flakes - 2.5 tbsp",
      "Sugar - 2.5 tbsp",
      "Soy Sauce - 1 tbsp",
      "Minced Garlic - 1 tbsp",
      "Sesame oil - 1 tbsp",
      "Sesame seeds - Few shakes garnish",
    ],
    instructions: [
      "Wash all three cucumbers and skin each cucumber with a peeler. Do not peel-off all of the skin, rather make uneven peels",
      "Chop cucumbers into half inch circle",
      "Pour salt onto the cucumbers and give them a good mix with your hands to coat thoroughly. Set the cucumbers aside for 30 minutes and they will slowly release water. Rinse them off and get rid of excess water",
      "For the marinade: Thoroughly mix vinegar, Korean red chilli powder, sugar, soy sauce, sesame oil and minced garlic in a bowl",
      "Dress the cucumbers with the marinade. Get in there with your hands and coat all of it!",
    ],
    notes: [
      "Refrigerate – taste better when served chilled",
      "The cucumbers taste best when eaten within 1-2 days",
      "When placed in refrigerator, the cucumbers will release water. This is normal - no worries",
      "Watch video below from Future Neighbor for a detailed walk-through",
    ],
    cuisineType: recipePath.korean,
    youtube: "'https://www.youtube.com/watch?v=d75qCghJAu4'",
  },

  {
    id: 6,
    img: "koreanFriedChicken.jpg",
    title: "Korean Fried Chicken",
    original: "This recipe is taken from the Future Neighbor YouTube channel which can be found ",
    originallink: "https://futuredish.com/kyochon-chicken/",
    description: "Sweet and spicy fried chicken. Serve with rice and vegetables of your choosing.",
    ingredients: [
      "Chicken wings - ~500g",
      "Milk - 500ml",
      "Salt - 4/5 shakes",
      "Pepper - 4/5 shakes",
      "Starch Powder",
      "Soy sauce - 2 tbsp",
      "Sugar - 1.5 tbsp",
      "Mirin - 1.5 tbsp",
      "Vinegar - 1 tbsp",
      "Honey - 1 tbsp",
      "Black pepper - few shakes",
      "Oyster sauce - ½ tbsp",
      "Dried red chilli pepper - ½ pepper",
    ],
    instructions: [
      "Give the chicken wings a wash - get rid of any debris on them.",
      "Take out a large mixing bowl and place the cleaned chicken wings in. Then pour in the milk. Let it sit in the milk for 20 minutes.",
      "After 20 minutes, drain-off the milk and let the chicken pieces sit in the strainer to drip-off excess milk. (Note: Do not wash off the milk off)",
      "Next, place the chicken wings in a large plastic (ziploc) bag. Season them with salt and pepper. Seal the bag and give it a nice shake. Then add in the starch powder and give another mix. ",
      "For the sauce: mix the soy sauce, sugar, mirin, vinegar, honey, black pepper, oyster sauce, and dried red chilli pepper together.",
      "Put frying pan on a medium-high heat. Place enough oil to create a thin layer on the bottom of the pan. Once it gets hot, place the wings in. Remember to tap-off the extra starch powder from the wings before placing them into the oil. ",
      "Fry on both-sides until they get nice and crispy. Make sure to flip them and move them around so that they don't burn. You can also reduce the heat to a low if you see the the oil is splashing around too much. ",
      "Once they wings get golden-crispy, turn off the heat and put them onto a plate.",
      "Remove oil and give frying pan a quick wipe-down.",
      "Then put frying pan on a medium-low heat. Once it is hot, place the sauce in.",
      "Mix the sauce around with a spatula until it thickens up. Should take around 2-3 minutes, but often takes us longer.",
      "Then toss in the fried chicken and mix until each piece is well coated.",
      "Serve on a plate. Enjoy!",
    ],
    notes: [
      "We generally double the ingredients for the sauce because we like it so much",
      "Drumsticks/thighs can also be used",
    ],
    cuisineType: recipePath.korean,
    youtube: "'https://www.youtube.com/watch?v=QL5erfS7cQI'",
  },
  {
    id: 7,
    img: "thaiSalad.jpg",
    title: "Thai-Style Salmon Salad",
    original: "This recipe is taken from Marion's Kitchen YouTube channel which can be found ",
    originallink: "https://www.marionskitchen.com/thai-style-salmon-salad/",
    description: "Perfect for lunch or a warm evening.",
    ingredients: [
      "Salmon fillets - 2",
      "Vegetable oil - 1 tbsp",
      "Small onion - 1",
      "Cucumber - 1",
      "Coriander (finely chopped) - ¼ cup",
      "Spring onion (finely sliced) - ¼ cup",
      "Mint leaves - ½ cup",
      "Chilli flakes (optional) - to taste",
      "Salt",
      "Red chilli (sliced) - 1",
      "Garlic glove - 1",
      "Fish sauce - 3 tbsp",
      "Lime juice - 3 tbsp",
      "Palm sugar - 2 tbsp",
    ],
    instructions: [
      "For the dressing, place the chilli, garlic and a pinch of salt in mortar. Use a pestle to pound to a rough paste. In a small bowl combine the chilli garlic paste, fish sauce, lime juice and palm sugar",
      "Season the salmon fillets with salt. Heat a frying pan over high heat. When the pan is hot, add the oil to the pan. Then add the salmon fillets and place a saucepan lid on top of the salmon so that the fillets are gently pressed to the bottom of the pan and to prevent oil splatter. Cook for 2-3 minutes each side or until cooked to your liking. Transfer to a plate to rest until cool enough to handle with your fingers.",
      "Place the onion and cucumber in a large bowl. Flake the salmon into large chunks and add to the bowl. Add the coriander, spring onion and mint. Add the dressing. Toss gently to combine. Add chilli flakes to taste (optional).",
    ],
    notes: [
      "",
    ],
    cuisineType: recipePath.thai,
    youtube: "'https://www.youtube.com/watch?v=rdb79zaytLg'",
  },
  {
    id: 8,
    img: "",
    title: "Spicy Miso Braised Aubergine",
    original: "This recipe is taken from Marion's Kitchen YouTube channel which can be found ",
    originallink: "https://www.marionskitchen.com/spicy-miso-braised-eggplant/",
    description: "Great vegetarian recipe!",
    ingredients: [
      "Aubergines - 3",
      "Oil - 1 tbsp",
      "Miso paste - 2 tbsp",
      "Soy Sauce - 2 tbsp",
      "White vinegar 1 tbsp",
      "Beef or vegetable stock - ½ cup",
      "Sugar - 2 tsp",
      "Ginger (finely grated) - 1 tbsp",
      "Garlic (finely grated) - 3 cloves",
      "Corn flour (½ tsp) mixed with water (1 tbsp)",
      "Spring onions, to serve",
      "Chilli oil, to serve",
      "Salt",
    ],
    instructions: [
      "Liberally salt the aubergines wedges and leave them to drain in a colander for about 30 minutes.",
      "In the meantime, in a bowl, mix together the miso paste, soy sauce, vinegar, beef stock, sugar, ginger and garlic.",
      "Drain the eggplant on paper towel and squeeze with extra paper towel to dry off the eggplant as much as possible.",
      "Heat the vegetable oil in a medium heavy-based saucepan over medium-high heat. Add a half of the eggplant and sear for 2-3 minutes on each side. Then pile them up in one side of the pan and add the other half of the eggplant and sear those. Then mix all the eggplant pieces together. Pour over the sauce. Cover with a lid, turn the heat down to medium and simmer for 10 minutes.",
      "Stir through the cornflour mixture. Simmer for another minute to allow the sauce to thicken. Transfer to a serving plate. Drizzle with chilli oil and sprinkle with the spring onion.",
    ],
    notes: [
      "You could serve this with rice or vegetables.",
    ],
    cuisineType: recipePath.japanese,
    youtube: "'https://www.youtube.com/watch?v=o9AlEklkw1Q'",
  },
  {
    id: 9,
    img: "channaGosht.jpg",
    title: "Channa Gosht",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "Different from your typical curry but tastes amazing!",
    ingredients: [
      "Ground coriander - 3 tbsp",
      "Ground cumin - 2 tbsp",
      "Ground black pepper - 1 tsp",
      "Red chilli powder - 1 tsp",
      "Ground turmeric - 1 tsp",
      "Paprika - 1 tbsp",
      "Rapeseed oil - 5 tbsp",
      "Lamb chops - 1kg on the bone",
      "Large onions (finely chopped) - 2",
      "Green chillis (finely chopped) - 4",
      "Mixed garlic and ginger paste - 3 tbsp",
      "Chopped tomatoes - 400g",
      "Channa dhal lentils (soaked for 20 minutes) - 150g",
      "Salt",
      "Garam masala - 1 tsp",
      "Coriander (chopped) - 3 tbsp",
      "Rice and/or Naan, to serve.",
    ],
    instructions: [
      "Stir all the ground spices together in a bowl.",
      "Mix 1 tablespoon of the ground spice mixture with 1 tablespoon of the oil and rub this into the meat. Leave to marinate for 2 hours if possible.",
      "When ready to cook, heat 2 tablespoons of oil in a large fying pan over a medium-high heat and brown the meat for a couple of minutes on each side. Do this in batches to ensure the meat doesn't stew.",
      "Transfer the meat to a plate and pour the rest of the oil in the pan.",
      "Fry the onions and chillis over a medium-high heat until the onions are soft and translucent.",
      "Add the garlic and ginger paste and the rest of the ground spices. Stir this up and add the meat, tomatoes, channa dhal and just enough water to cover.",
      "Simmer the curry until the channa dhal is soft and the meat is super tender. This will take around an hour. You may need to add water during the cooking process to ensure the lentils don't stick to the pan.",
      "This is a thicker curry, so once the lentils are soft, keep simmering until you have a thick sauce.",
      "Add salt to taste and sprinkle with the garam masala.",
      "Serve with fresh coriander and rice/naan if desired.",
    ],
    notes: [
      "Be careful that the lentils don't stick to the pan and burn.",
      "Highly recommend this book if you love Indian cuisine!"
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 10,
    img: "chickenSeekhKebabs.jpg",
    title: "Chicken Seekh Kebabs",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "Easy healthy kebabs that taste amazing! Eaten with naan bread, raita and a salad of your choice",
    ingredients: [
      "Chicken mince - 500g",
      "Onion - 1 (finely chopped)",
      "Egg - 1 (skewer cooking only)",
      "Green chillies - 2 (finely chopped)",
      "Ground cumin - 1 tsp",
      "Ground coriander - 1 tsp ",
      "Chilli powder/flakes (to taste)",
      "Ground turmeric - 1/2 tsp",
      "Salt - 1 tsp",
      "Dried thyme - 1 tsp",
      "Butter - 3 tbsp",

    ],
    instructions: [
      "Use your hands to mix all ingredients together.",
      "Divide the mixture into equal amounts (I did 6 because I had 6 pieces of naan bread) and shape to resemble a kebab.",
      "Ensure each kebab is fair tightly packed together to avoid it breaking apart when cooking.",
      "Heat a small amount of oil over a medium high heat.",
      "When hot add the kebabs to the pan.",
      "Do not turn the meat too soon or too frequently, you want a each side nicely charred before turning.",
      "Once cooked, plate up and serve with naan bread, raita, and salad",
    ],
    notes: [
      "I used a griddle pan to cook these rather than the recommended skewer",
      "Highly recommend this book if you love Indian cuisine!",
      "These steps are for pan cooking only. Skewer cooking requires a little more effort."
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 11,
    img: "raita.jpg",
    title: "Raita",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "Slightly spicy yoghurt sauce that can be used to accompany a variety of dishes. We recommend it with our chicken seekh kebab recipe!",
    ingredients: [
      "Cumin seeds - 1 tsp (toasted)",
      "Coriander - Large bunch",
      "Green chillies - 3 (or to taste)",
      "Garlic cloves - 2",
      "Lemon juice - 1 lemon",
      "Salt",
      "250ml plain yoghurt",
    ],
    instructions: [
      "Blend all ingredients together except for the yoghurt. Note: you may need a drop of water to help it blend",
      "Fold the blended mixture into the yoghurt and you're done!",
    ],
    notes: [
      "You can leave out the yoghurt if you want this to be a chutney rather than a raita",
      "Highly recommend this book if you love Indian cuisine!",
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 12,
    img: "thaiBasilPork.jpg",
    title: "Thai Basil Pork",
    original: "This recipe is taken from Seonkyoung Longest's YouTube channel which can be found ",
    originallink: "https://seonkyounglongest.com/thai-basil-pork-belly/",
    description: "Spicy thai pork belly dish that will leave you wanting more!",
    ingredients: [
      "Pork belly - ~500g",
      "Soy sauce - 1 tsp",
      "Fish sauce - 1 tsp",
      "Black or white pepper - 1/4 tsp",
      "Lime juice - 1 tsp",
      "Garlic cloves - 4",
      "Chilli - 1-3",
      "Green beans - ~150g",
      "Soy sauce - 2 tbsp",
      "Fish sauce - 2 tbsp",
      "Palm sugar - 3-4 tbsp",
      "Basil - Handful",
      "Egg - 1 for each person",
      "Rice - Enough for 2 people",
    ],
    instructions: [
      "Combine pork belly, 1 tsp soy sauce, 1 tsp fish sauce, 1/4 tsp black pepper and 1 tsp lime juice in a mixing bowl. Let it marinate for 20 minutes and meanwhile, prepare other ingredients.",
      "Heat a wok or a large skillet over high heat and add cooking oil. Add marinated pork belly and cook until pork belly is crispy, about 5 to 6 minutes. You may see lots of fat melted in wok, so turn off heat and remove most of pork fat but leave 1 tbsp in wok.",
      "Place wok back to over high heat then add chopped garlic and chili. Stir fry until you smell aroma, about 30 seconds. Add green beans, soy sauce, fish sauce and palm sugar. Stir everything together, about 2 minutes. ",
      "Stir in basil and turn off heat immediately. Serve with freshly cooked warm jasmine rice, fried egg and lime to your taste. Enjoy! ",
    ],
    notes: [
      "The recipe calls for jasmine rice but you can use any",
      "Ideally made with Thai basil but Italian basil works great too",
    ],
    cuisineType: recipePath.thai,
    youtube: "'https://www.youtube.com/watch?v=ZHQhGI9XNbc'",
  },
  {
    id: 13,
    img: "mincedPorkAndLongBean.jpg",
    title: "Minced Pork With Green Beans",
    original: "This recipe is taken from Souped Up Recipes' YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/ground-pork-stir-fry-with-long-beans/",
    description: "Super easy but incredibily flavourful!",
    ingredients: [
      "Green beans - ~400g",
      "Minced pork - 250g",
      "Garlic cloves - 5-6",
      "Ginger - 1.5 tsp",
      "Chilli - 6 (to taste)",
      "Soy sauce - 1 tbsp",
      "Oyster sauce - 1 tbsp",
      "Fish sauce - 1 tbsp",
      "Dark soy sauce - 1 tsp",
      "Salt - To taste",
    ],
    instructions: [
      "Dice 400 grams of asparagus beans into 1/3 of an inch long pieces. You can use green beans as a replacement if you don’t have access to buy it.",
      "Dice 6 pieces of that bird eye chilies, 5 cloves of garlic, and 1/2 tbsp of ginger.",
      "Heat the wok. Add a drizzle of cooking oil and toss in the diced long beans, chili, garlic, and ginger. Stir them on medium heat for a few minutes or until the garlic and ginger are fragrant.",
      "Then add all the seasoning: 1 tbsp of soy sauce, 1 tbsp of oyster sauce, 1 tbsp of fish sauce. Keep stirring until the diced long beans are cooked through.",
      "Once you don’t taste that distinctly beany flavor, you can push everything to the side and toss in 250 grams of ground pork. Turn the heat to high and use your spatula to break up any big chunks of meat.",
      "Once you don’t see any pink color, the pork is cooked through. Mix everything together. Add 1 tsp of dark soy sauce to make the color deeper. Give it a taste to see if you need to adjust the flavor.",
    ],
    notes: [
      "You should really check out this YouTube channel - she has great simple recipes!",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=FcVvyJi-DDE'",
  },
  {
    id: 14,
    img: "kungPaoChicken.jpg",
    title: "Kung Pao Chicken",
    original: "This recipe is taken from Souped Up Recipes' YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/kung-pao-chicken-2019-version/",
    description: "Super tasty with a bit of a kick!",
    ingredients: [
      "Chicken breast - ~500g",
      "Salt - 1 tsp",
      "Onion powder - 1/2 tsp",
      "Chinese cooking wine - 2 tsp",
      "Egg white - 1",
      "Cornstarch - 1.5 tbsp",
      "Sichuan Dou Ban Jiang - 1 tbsp",
      "Soy sauce - 1 tbsp",
      "Black vinegar - 1.5 tbsp",
      "Dried red chillies - 1 handful",
      "Sichuan peppercorns (finely ground) - 1 tbsp",
      "Garlic - 1 tbsp",
      "Ginger - 1 tsp",
      "Brown sugar - 2 tbsp",
      "Water - 1/2 cup",
      "Cornstarch - 2 tsp",
      "Spring onion - 4",
      "Roasted peanutes - 1/3 cup",
    ],
    instructions: [
      "Cut chicken breast into 1/2 inch cubes. Marinade it with 1 tsp of salt, 1/2 tsp of onion powder, and 2 tsp of Chinese cooking wine. Then coat the chicken with 1.5 tbsp of cornstarch and 1 egg white. Keep mixing it until it gets to a creamy and velvety texture. Set it aside for 20 minutes. ",
      "The cornstarch and egg white combination is a technique in Chinese cuisine, known as velveting. It creates a starchy layer that can preserve the moisture of meat while cooking. Which leads to the juicy, tender meat.",
      "Dice 1 tbsp of Sichuan peppercorns. Cut a handful of red dried chilies into short pieces. Roughly diced 4 pieces of white parts of the scallion. Prepare 1 tbsp of minced garlic, and 1 tsp of minced ginger.",
      "Heat your wok until it is smoking. Add a generous amount of oil. Give it a toss so the oil covers the bottom. Wait for it to smoke. That means the wok is hot enough. Then add the chicken. Spread the meat so most pieces are touching the bottom of the wok. Do not stir and flip the meat or else it will stick to the wok immediately.",
      "Once the chicken is cooked and you got some nice brown color. Turn off the heat and take it out.",
      "Drizzling in a little more oil to the wok along with 1 tbsp of Sichuan Dou Ban Jiang. Stir this on low heat for a couple of minutes. When you see all the oil becomes a red-orange color, add the garlic, ginger, diced Sichuan peppercorn, red dried chilies and 2 tbsp of brown sugar. This is an extremely flavorful dish. The purpose of the sugar is to balance all the tanginess. You just stir until the sugar melts. Introduce the chicken back into the wok. Add the seasonings - 1 tbsp of soy sauce, 1.5 tbsp of Chinese black vinegar and a tsp of dark soy sauce. Pouring some cornstarch water and stir immediately.",
      "When the sauce thickens, throw in the toasted peanuts and the scallions. Toss everything together. Taste it to adjust the flavor. Mine does need one more splash of vinegar. Give it a final stir and you are ready to enjoy",
    ],
    notes: [
      "You should really check out this YouTube channel - she has great simple recipes!",
      "You can use normal rice vinegar instead of black vinegar",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=wVSfiKFxsjw'",
  },
  {
    id: 15,
    img: "HKStyleVegetableChowMein.jpg",
    title: "HK Style Vegetable Chow Mein",
    original: "This recipe is taken from Souped Up Recipes' YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/hk-chow-mein-stir-fried-noodles/",
    description: "Boost your veg numbers with this tasty recipe!",
    ingredients: [
      "Fresh egg noodles - 150g OR Dried egg noodles - 100g",
      "Bean sprouts - 40g",
      "Carrot - 40g",
      "Small onion - 1",
      "Garlic chives - 40g",
      "Garlic cloves - 1",
      "Olive oil",
      "Water - 1 litre",
      "Oil - 1-2 tbsp",
      "Soy sauce - 1 tbsp",
      "Oyster sauce - 1 tsp",
      "Dark soy sauce - 1 tsp",
      "Sugar - 1/4 tsp",
    ],
    instructions: [
      "Bring 1 liter of water to a boil. Add a drizzle of olive oil, then toss in 100 grams of dry egg noodles (or 150 grams of fresh egg noodles).",
      "Turn off the heat immediately, let the noodles soak in the hot water. Gently loosen up all the noodles with a pair of chopsticks. Dry egg noodles will take about 3-5 minutes; fresh egg noodles will need 2-3 minutes depending on the thickness.",
      "Take out the noodles and spread them on a rack for at least 10 minutes. The rest of the heat will help to evaporate the excess moisture.",
      "Cut the noodles into 6 inches long pieces with a pair of scissors.",
      "Julienne some vegetables. I used 40 grams (1.41 oz) of bean sprouts, 40 grams (1.41 oz) of carrot, 40 grams (1.41 oz) of garlic chives, 1/4 of an onion, and 1 clove of garlic.",
      "In a sauce bowl, mix 1 tbsp of soy sauce, 1 tsp of dark soy sauce, 1 tsp of oyster sauce and 1/4 tsp of sugar.",
      "Turn the heat to high. Heat your wok until smoking hot. Add 2 tbsp of oil. Swirl the oil around to cover the bottom to create a non-stick layer. Add in the noodles. Use two pairs of chopsticks to fluff the noodles for a couple of minutes.",
      "Add the vegetables. Pour in the sauce in batches. Stir to combine all the flavor. Turn off the heat and serve the noodles on the plate.",
    ],
    notes: [
      "We substitute the garlic chives with spring onions",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=4uos7_k5-IM'",
  },
  {
    id: 16,
    img: "cashewChicken.jpg",
    title: "Cashew Chicken",
    original: "This recipe is taken from Souped Up Recipes' YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/cashew-chicken-in-15-min-no-more-take-out/",
    description: "Super quick and super delicious!",
    ingredients: [
      "Chicken breast - ~500g",
      "Salt - 1/3 tsp",
      "Baking soda - 1/3 tsp",
      "White pepper - To taste",
      "Cornstarch - 2.5 tsp",
      "Chinese cooking wine - 1.5 tbsp",
      "Soy sauce - 1.5 tbsp",
      "Oyster sauce - 1 tbsp",
      "Hoisin sauce - 1 tbsp",
      "Ketchup - 1 tbsp",
      "Water - 2 tbsp",
      "Vegetable oil - 2-3 tbsp",
      "Garlic cloves - 3",
      "Ginger - 1/2 inch",
      "Bell pepper - 1",
      "Celery - 2 stalks",
      "Cashew nuts - 1/2 cup",
    ],
    instructions: [
      "Cut chicken breast into bite size pieces",
      "Marinate it with 1/3 tsp of salt and 1/3 tsp of baking soda, some white pepper to taste, 2.5 tsp of cornstarch, and 1.5 tbsp of Chinese cooking wine. Gently rub for a few minutes or until the chicken feels velvety.",
      "In a bowl, combine the following ingredients and set it aside: 1.5 tbsp of soy sauce, 1 tbsp of oyster sauce, 1 tbsp of hoisin sauce, 1 tbsp of ketchup, 2 tbsp of water.",
      "Turn the heat to high and heat the wok until it is smoking hot. Add some oil and toss it around to create a nonstick layer.",
      "Add the chicken and stir for 2-3 minutes or until the chicken changes color. Turn off the heat and take the chicken out. Make sure you tilt the wok to leave the oil behind.",
      "Turn the heat back on medium, use the remaining oil to saute the garlic, ginger, scallion, and celery. Stir over medium heat for a couple of minutes. Pour in the sauce and introduce the chicken back into the wok. Keep mixing for another 2-3 minutes or until the chicken is cooked through; Then, add the bell pepper and the roasted cashews. Give it a final toss. That’s it, you are done.",
    ],
    notes: [
      "You can also use chicken thigh instead if you prefer",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=gqYUYAhwffM'",
  },
  {
    id: 17,
    img: "bangBangChicken.jpg",
    title: "Bang Bang Chicken",
    original: "This recipe is taken from Marion Kitchen's YouTube channel which can be found ",
    originallink: "https://www.marionskitchen.com/sichuan-bang-bang-chicken/",
    description: "Great for the summer!",
    ingredients: [
      "Chicken breast - 2",
      "Ginger - 4 slices (bruised)",
      "Garlic cloves - 3 (bruised)",
      "Spring onions - 2 (finely sliced)",
      "Cucumber - 1 (finely sliced)",
      "Sichuan peppercorns - 1/2 tsp",
      "Sesame seeds - 2 tbsp",
      "Soy sauce - 3 tbsp",
      "Black or white vinegar - 1 tbsp",
      "Chilli oil - 1 tbsp",
      "Salt - 1/4 tsp",
    ],
    instructions: [
      "Place 4 cups of water in medium saucepan. Add the ginger, garlic and spring onions. Add the chicken breasts and place over high heat. Wait for the water to just start to gently simmer, then turn the heat down to medium and continue to simmer for 8 minutes. Then turn the heat off (leave the saucepan on the hot stovetop) and leave for 10 minutes. Transfer the chicken to a bowl of iced water. Reserve ½ cup of the poaching liquid.",
      "When the chicken is cool. Use a rolling pin to bang the chicken pieces to loosen them. Then tear into fine shreds.",
      "For the dressing, heat the Sichuan peppercorns in a dry frying pan until fragrant. Use a mortar and pestle to grind to a fine powder. Transfer to a bowl. Add the toasted sesame seeds, soy sauce, vinegar, chilli oil, salt and the reserved poaching liquid.",
      "Scatter the cucumber over a serving plate. Top with the shredded chicken. Spoon over the dressing. Add additional chilli oil if you like it spicy.",
    ],
    notes: [
      "Watch the video for an explanation on why cooking the chicken like this is necessary",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=eMPWVQ0ZmEM'",
  },
  {
    id: 18,
    img: "chineseBroccoli.jpg",
    title: "Chinese Broccoli (Gai Lan)",
    original: "This recipe is taken a random recipe website I stumbled across which can be found ",
    originallink: "https://www.recipetineats.com/chinese-broccoli-with-oyster-sauce/",
    description: "Great side dish for almost anything!",
    ingredients: [
      "Chinese broccoli - 1 bunch",
      "Corn flour - 3/4 tsp",
      "Water - 3 tbsp",
      "Oyster sauce - 2 tbsp",
      "Soy sauce - 2 tsp",
      "Chinese cooking wine - 1 tbsp",
      "Sesame oil - 1/2 tsp",
      "Vegetable oil - 1/2 tbsp",
      "Sugar - 1/2 tsp",
      "Garlic clove - 1",
      "Ginger - 1 tsp",
    ],
    instructions: [
      "Trim ends off Chinese Broccoli. If any stems are super thick, cut them in half (you want all stems approximately the same width).",
      "Steam Chinese Broccoli using whatever method you want - I microwave in a steamer on high for 4 minutes. The stem should be just cooked - not super soft and floppy.",
      "Stack the Chinese broccoli together and cut into 4inch/10cm lengths, then stack neatly on top of each other.",
      "Combine water and corn flour in small saucepan, mix to dissolve.",
      "Then add remaining ingredients, turn stove onto medium and bring to boil. Boil for 30 seconds to allow to thicken, then remove from stove.",
      "Drizzle over Chinese broccoli and serve. Best served warm.",
    ],
    notes: [
      "I doubled the ingredients as we had a lot of the Gai Lan",
      "It even tastes great warmed up the next day if you have some leftover!",
    ],
    cuisineType: recipePath.chinese,
  },
  {
    id: 19,
    img: "chickenMasala.jpg",
    title: "Chicken Masala",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "Very tasty curry that is quite simple and doesn't take too much time to make!",
    ingredients: [
      "Rapeseed oil - 3 tbsp",
      "Cumin seeds - 1 tsp",
      "Coriander seeds - 1 tsp",
      "Bay leaf - 1",
      "Onions - 4 (finely chopped)",
      "Ground cumin - 1 tbsp",
      "Ground coriander - 1 tbsp",
      "Mixed garlic and ginger paste - 2 tbsp",
      "Chilli powder - 1 tsp (or to taste)",
      "Ground turmeric - 1 tsp",
      "Tomatoes - 4 tennis-ball-sized (finely chopped)",
      "Passata - 100ml",
      "Chicken thighs - ~500g",
      "Garam masala - 2 tsp",
    ],
    instructions: [
      "In a large pan with a lid, heat the oil over medium-high heat.",
      "When hot, add the cumin seeds, coriander seeds, and bay leaf. Temper in the oil for 30 seconds.",
      "Pour in the chopped onions and fry for 10 minutes until soft and translucent.",
      "Stir in the ground cumin, coriander, garlic and ginger paste, chilli powder and turmeric followed by the chopped tomatoes and allow to sizzle over medium heat for about 5 minutes.",
      "Add salt to the mixture to help the onions release moisture.",
      "Stir in the passata, and sizzle for ~30 seconds, then add the chicken. Stir it all together and add enough water to just cover the chicken.",
      "Cover the curry and let it simmer for about 10 minutes.",
      "Remove the lid and stir, adding a drop more water if you prefer a thinner sauce or turn up the heat if it's too runny.",
      "Stir in 1 teaspoon of garam masala and taste. Add more salt or garam masala if needed.",
    ],
    notes: [
      "Serve with rice and/or naan.",
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 20,
    img: "soupedNoodleSoup.jpg",
    title: "Minced Pork Noodle Soup",
    original: "This recipe is taken from Souped Up Recipes' YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/ground-pork-noodle-soup/",
    description: "Quick but lots of flavour!",
    ingredients: [
      "Oil - 3 tbsp",
      "Garlic cloves - 10",
      "Ginger - 1/2 inch",
      "Spring onion - 3",
      "Chilli flakes - 1 tbsp",
      "Dou Ban Jiang - 1.5 tbsp",
      "Minced pork - 400g",
      "Chinese cooking wine - 2 tbsp",
      "Soy sauce - 1 tbsp",
      "Oyster sauce - 1 tbsp",
      "Dark soy sauce - 1 tsp",
      "Water - 1/4 cup",
      "Noodles - Enough for 2 people",
      "Water - 5-6 cups",
      "Salt - 1 tsp",
      "Pak soi - 1 pack",
      "Fried egg - 1 each",
    ],
    instructions: [
      "Add oil, garlic, ginger, the white part of the scallion, Sichuan doubanjiang, and chili flake to the wok. Stir over medium heat for 2-3 minutes to activate the Dou Ban Jiang flavor.",
      "Add minced pork and stir over high heat for a few minutes or until cooked through.",
      "Season with Chinese cooking wine, soy sauce, and oyster sauce, and dark soy sauce. Pour in 1/4 cup of water and let it mingle a little bit. Remove the Saozi from the wok and set it aside.",
      "This will taste a lot saltier compare to your preference, that is because this ground pork is a noodle topping, or what we call Saozi (臊子) in Chinese, you are not supposed to eat on its own.",
      "Bring 5 -6 cups of water, if you have stock, that will be even better. I am not washing the wok because I don’t want to waste the flavor.",
      "We will bring this to a boil which will take a few minutes. It should be enough time for you to wash the baby bok choy and cut them open.",
      "The noodles - What I am using here are fresh ramen noodles but you can use other types because I have tried so many, such as egg noodles, rice noodles, mung bean noodles, even instant noodles will work. Depending on the thickness and the brand, the cooking time may variant. If you are not sure, read the instructions on the package before you cook it.",
      "Once the water comes to a boil, throw in 4-5 servings of noodles. Just for reference, it will be 400-500 grams of fresh noodles or 250-300 grams of dried noodles. Add some salt to taste. I used about 1/2 tbsp. My noodles are fresh so they just need a few minutes of simmering, which should be enough time for you to pan-fry some eggs on a different stove. This is optional. Just to add a little extra protein.",
      "I think the noodles are almost done now. Throw in the baby bok choy and let it blanch for just 30 seconds and dinner is ready.",
      "To make a serving, you just put some noodles in a bowl along with the broth. Scoop a few big spoonfuls of minced pork on the top. Put some baby bok choy to balance the flavor",
      "This is a super quick dinner on a limited budget but it is very tasty. We made the ground pork salty and savory so you can gradually mix it into the noodle soup as you are eating it. If you got the time and effort, you can definitely upgrade the broth to chicken stock. When I was little, my family wasn’t that fancy, we just use water.",
    ],
    notes: [
      "You should really check out this YouTube channel - she has great simple recipes!",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=aYhAZUc8zTk'",
  },
  {
    id: 21,
    img: "kimchiStew.jpg",
    title: "Kimchi Stew (kimchi jigae)",
    original: "This recipe is taken from the recipe book Our Korean Kitchen which can be found ",
    originallink: "https://www.amazon.co.uk/Our-Korean-Kitchen-Jordan-Bourke/dp/0297609718/ref=sr_1_1?crid=XBR9K2KK65H1&dchild=1&keywords=our+korean+kitchen&qid=1602609425&sprefix=our+korean+%2Caps%2C157&sr=8-1",
    description: "Incredibly tasty and warming stew. Nice and simple recipe.",
    ingredients: [
      "Gochuchang paste - 1 tbsp",
      "Garlic cloves - 2 (crushed)",
      "Sesame seed oil - 2 tbsp",
      "Spring onions - 2 (finely chopped)",
      "Tofu - 200g",
      "Beef stock - 800ml",
      "Pork belly - 200g (cut into thin bite-sized pieces)",
      "Kimchi - 300g",
      "Kimchi juice - 4 tbsp",
      "Ginger - 2.5cm (finely grated)",
      "Honey - 2 tsp",
      "Courgette - 1/2 (halved lengthways and sliced into half-moon shapes)",
      "Rice, to serve",
    ],
    instructions: [
      "Heat the sesame seed oil in a large pan over a medium heat, and add the pork and kimchi. Saute for 8-10 minutes until they start to caramelise.",
      "Add in the gochuchang paste, garlic, ginger, and honey. Fry for 2 minutes, stirring regularly to avoid burning. ",
      "Add in the kimchi juice and stock and bring to the boil. Reduce the heat a little and simmer for 20 minutes.",
      "Add the courgette, tofu, and most of the spring onions and simmer for another 5 minutes or until the courgette is cooked but retains a little bite.",
      "Serve in a bowl and top it with the remaining spring onion.",
      "Serve with rice on the side in a separate bowl.",
    ],
    notes: [
      "We generally do at least one side dish of vegetable to go with this dish.",
      "We just use one whole packet of tofu",
      "We just use one whole courgette "
    ],
    cuisineType: recipePath.korean,
  },
  {
    id: 22,
    img: "taiwanBeefNoodleSoup.jpg",
    title: "Taiwanese Beef Noodle Soup",
    original: "This recipe is taken from Marion's Kitchen YouTube channel which can be found ",
    originallink: "https://www.marionskitchen.com/taiwanese-beef-noodle-soup/",
    description: "This can be done in a pressure cooker in 40 minutes or in 2-3 hours on the hob!",
    ingredients: [
      "Oil - 3 tbsp",
      "Onion - 1 (sliced)",
      "Garlic cloves - 6",
      "Spring onions - 6 (cut into few inch pieces)",
      "Ginger - 3 slices",
      "Red chillies - 2 (deseeded)",
      "Dou Ban Jiang - 3 tbsp",
      "Tomatoes - 2 (cut into wedges)",
      "Brown sugar - 2 tbsp",
      "Dark soy sauce - 1/4 cup",
      "Soy sauce - 1/2 cup",
      "Chinese cooking wine - 1/2 cup",
      "Star anise - 3",
      "Beef - 500g - 1kg (we did 600g)",
      "Beef stock - 4 cups",
      "Egg or ramen Noodles - enough for 2 people",
      "Pak choi - 1 pack",
      "Spring onion - 1 (finely chopped, to serve)",
      "Coriander - to serve",
    ],
    instructions: [
      "Heat the oil in a large frying pan or wok over medium-high heat. Add the onion, garlic, ginger, spring onions and chillies. Stir-fry for 2-3 minutes or until the onions have softened but not coloured. Add the doubanjiang and stir-fry for another minute. Then stir through the tomatoes and the brown sugar. Now add the dark soy sauce, soy sauce, Chinese wine and the star anise. Add the beef and mix to combine. Transfer the mixture into the pot of a pressure cooker (if using). Add the beef stock and 2 cups of water. Mix to combine. Secure the lid and pressure cook on high for 40 minutes.",
      "Release the pressure from the pressure cooker (if using) and when it’s safe, remove the lid. Use a spoon to scoop off most of the excess fat from the top of the soup. Then remove the ginger slices and the star anise. Taste the soup and add more salt to your taste.",
      "Divide the noodles among serving bowls. Top with bok choy and chunks of beef. Ladle over the soup. And sprinkle over the spring onion and coriander.",
    ],
    notes: [
      "My new favourite homemade noodle soup or ramen recipe!",
      "Perhaps controversially put under the Chinese recipes, purely for ease whilst we have a low number of recipes.",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=nVWAd0zbWNY'",
  },
  {
    id: 23,
    img: "shoyuRamen.jpg",
    title: "Quick Shoyu Ramen Recipe",
    original: "This recipe is taken from way of Ramen's YouTube channel which can be found ",
    originallink: "https://www.youtube.com/watch?v=r3_tcQiyGG8",
    description: "Very fast, somewhat authentic (I think) ramen recipe!",
    ingredients: [
      "Hot water - 300ml (plus more for boiling the egg)",
      "Soy sauce - 1 tbsp",
      "Chicken stock - I did a quarter of a cube",
      "Ginger - 1 grated pinch",
      "Spring onion - 1 finely sliced",
      "Lard - 1 tsp (we used bacon fat)",
      "Seaweed - 1 small rectangle for 1 person",
      "Pak choi (optional) - 1",
      "Soy sauce (for the tare) - 1 tbsp",
      "Sake (for the tare) - 1 tbsp",
      "Sugar (for the tare) - 1 tsp",
      "Noodles (1 person servign)",
      "Egg - 1"
    ],
    instructions: [
      "Place egg in boiling water for 6 minutes to make a runny boiled egg and place into cold water to cool before peeling.",
      "Add 1 tbsp soy sauce, 1 tbsp sake, and 1 tsp sugar into a pan over low heat. Mix to combine. Once bubbles start to form in the pan add egg(s) to the pan and roll it around in the tare for around 5 minutes.",
      "Slice white part of green onion. Grate small piece of ginger.",
      "In your ramen bowl add 1 tbsp soy sauce, small amount of stock powder/cube, white part of the finely sliced spring onion, the grated ginger, 1 tsp of lard/fat.",
      "Cook ramen noodles according to packet instructions.",
      "When the noodles are almost done, add 300 ml of water to your ramen bowl and it together with the ingredients already in it.",
      "Strain the noodles completely and add to the bowl.",
      "Top with your egg (sliced in half), seaweed, and finely sliced green part of the spring onion. We also added some pak choi and mushrooms.",
    ],
    notes: [
      "This is for one serving. Multiply as needed.",
      "We added extra vegetables to the recipe, these are marked (extra)",
    ],
    cuisineType: recipePath.japanese,
    youtube: "'https://www.youtube.com/watch?v=r3_tcQiyGG8'",
  },
  {
    id: 24,
    img: "beefMasalaCurry.jpg",
    title: "Lamb/Beef Masala Curry",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "Our favourite homemade curry!",
    ingredients: [
      "Rapeseed oil - 200ml (we use less)",
      "Bay leaf - 1",
      "5cm cinnamon stick",
      "6 green cardamon pods (or 3 black oens) - bruised",
      "Cumin seeds - 1.5 tsp",
      "Coriander seeds - 1.5 tsp",
      "Star anise - 2",
      "Large onions (finely chopped) -3",
      "Garlic and ginger paste - 4 tbsp",
      "Tomatoes (diced) - 4",
      "Turmeric - 1 tsp",
      "Basar curry masala - 1.5 tbsp",
      "Chilli powder - 1 tbsp (or to taste)",
      "Yoghurt - 2 tbsp (optional)",
      "Salt (to taste)",
      "Rice and/or Naan, to serve.",
    ],
    instructions: [
      "Heat oil in a large saucepan over a medium-high heat.",
      "When visibily hot, add the bay leaf, cinnamon, cardamon pods, cumin + coriander seeds, and the star anise for about 30 seconds",
      "Add the chopped onions and fry for 5-10 minutes until soft and lightly browned",
      "Stir in the garlic and ginger paste and fry for 30 more seconds",
      "Stir in the tomatoes, turmeric, basar, and chili powder",
      "Add the meat and brown fora  few minutes",
      "Add enough water to cover and simmmer for approximately 2 hours until the meat is how you like it",
      "Finish by stirring in the yoghurt 1 tablespoon at a time",
      "Service with rice and/or naan",
    ],
    notes: [
      "It usually takes a couple of hours for the beef to be nice and soft",
      "Highly recommend this book if you love Indian cuisine!"
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 25,
    img: "koreanBipimbap.jpg",
    title: "Bipimbap",
    original: "This recipe is taken from an awesome YouTube channel and can be found ",
    originallink: "https://futuredish.com/bibimbap/",
    description: "Great tasting vegetarian dish!",
    ingredients: [
      "Rice - 2 cup",
      "Carrot - 60 grams",
      "Zucchini - 60 grams length of your pointer finger",
      "Lettuce - 6 leaves",
      "Dried seaweed - 8 sheets",
      "Shiitake mushrooms - 4",
      "Soybean sprouts - 2 cup",
      "Egg - 2",
      "Gochujang - 4 teaspoons",
      "Soy sauce - 2 teaspoon",
      "Sesame oil - 2 teaspoon",
      "Vinegar - 1 teaspoon",
      "Sugar - 1 teaspoon",
      "Sesame seeds - 1 teaspoon",
    ],
    instructions: [
      "Julienne Carrot, Zucchini, Cabbage, Lettuce and Gim. Cut Shiitake Mushrooms into thin slices as well. ",
      "FOR SOYBEANS - Fill a pot with water. Season the water with a few shakes of salt. Place your soybean sprouts into the water. Then place on high heat. When it comes to boil, immediately drain and rinse under cold water. Set aside",
      "FOR ZUCCHINI, CARROT AND MUSHROOMS - For each vegetable, repeat the following steps: Put a frying pan on medium-high heat. Put some oil in. Once the pan is hot, place the vegetable in. Season with salt. Stir-around for 1-2 minutes until the vegetable begin to soften-up. Then place onto a plate and set aside",
      "FOR SAUCE - mix the gochuchang, soy sauce, sesame oil, garlic, vinegar, sugar, sesame seeds together",
      "Place one cup of rice at the bottom of your bowl. Then neatly arrange each vegetable (carrot, zucchini, cabbage, lettuce, shiitake mushrooms, gim, soybean sprouts) on top of the rice. Place a fried egg in the middle (optional). Then place a few scoops of the bibimbap sauce over the top.",
      "Then thoroughly mix the sauce in with all of the ingredients.",
    ],
    notes: [
      "This is for 2 servings, so multiply as necessary",
      "You can substitute out the vegetables to pretty much anything you like. We often use pak soi and spinach",
      "When pan-frying vegetables, cook them on a medium heat",
      "You can also add thin slices of beef if you want to make it non-vegetarian"
    ],
    cuisineType: recipePath.korean,
    youtube: "'https://www.youtube.com/watch?v=PmWLcTLAFys'",
  },
  {
    id: 26,
    img: "braisedFishInSeasonedSoy.jpg",
    title: "Braised Fish in Seasoned Soy",
    original: "This recipe is taken from the recipe book Our Korean Kitchen which can be found ",
    originallink: "https://www.amazon.co.uk/Our-Korean-Kitchen-Jordan-Bourke/dp/0297609718/ref=sr_1_1?crid=XBR9K2KK65H1&dchild=1&keywords=our+korean+kitchen&qid=1602609425&sprefix=our+korean+%2Caps%2C157&sr=8-1",
    description: "Amazing fish recipe!",
    ingredients: [
      "Soy Sauce - 50ml",
      "Soju (or sake or Chinese cooking wine (Shaoxing wine)) - 3 tbsp",
      "Mirin - 1 tbsp",
      "Honey - 1 tsbp",
      "Garlic cloves - 2 (crushed)",
      "Gochuguru red pepper powder - 1 tbsp",
      "Baby new potatoes - 250g",
      "White fish - 400g",
      "Shiitake mushrooms - 100g",
      "Red chilli - 1 (sliced)",
      "Cooked rice, to serve",
    ],
    instructions: [
      "Mix together the soy sauce, soju, mirin, honey, garlic, gochuguru powder, and 200ml of water in a bowl",
      "Place the potatoes in a medium saucepan and pour over the sauce",
      "Cover and bring to the boil over a high heat, then reduce the temperature and simmer for 10-15 minutes until the potatoes are almost cooked through",
      "Add in the fish and mushrooms and continue to simmer for 5-7 minutes until the fish, potatoes, and mushrooms are cooked",
      "Plate up and scatter over sliced chilli",
      "Serve with a bowl of rice and any other side dish",
    ],
    notes: [
      "Serves 2",
      "We usually serve it with a side of vegetables, such as pak soi or Chinese brocolli"
    ],
    cuisineType: recipePath.korean,
  },
  {
    id: 27,
    img: "grilledPorkBellyWithSesameDip.jpg",
    title: "Grilled Pork Belly with Sesame Dip",
    original: "This recipe is taken from the recipe book Our Korean Kitchen which can be found ",
    originallink: "https://www.amazon.co.uk/Our-Korean-Kitchen-Jordan-Bourke/dp/0297609718/ref=sr_1_1?crid=XBR9K2KK65H1&dchild=1&keywords=our+korean+kitchen&qid=1602609425&sprefix=our+korean+%2Caps%2C157&sr=8-1",
    description: "Excellent dish to share with friends and family!",
    ingredients: [
      "Sesame seed oil (for the dip) - 3 tbsp",
      "Salt - 1.5 tsp",
      "Black pepper - 0.5 tsp",
      "Pork belly - 650g (skin removed, cut into thin slices)",
      "Onion - 1 (thinly sliced)",
      "Garlic cloves - 6 (thinly sliced, lengthways)",
      "Iceberg lettuce - 1 head",
      "Kimchi, to serve",
      "Cooked rice, to serve",
      "Doen-jang soybean paste - 4 tbsp",
      "Gochuchang chilli paste - 3 tbsp",
      "Rice wine vinegar - 2 tbsp",
      "Sesame seed oil (for the sauce) - 1 tbsp",
      "Honey - 1 tbsp",
      "Garlic cloves - 3 (crushed)",
    ],
    instructions: [
      "Mix together the sesame seed oil, salt, and pepper and set aside",
      "Heat a grill pan over a medium-high heat. When hot, add the pork belly, onion and garlic, in batches if necessary",
      "Grill the pork for about 6 minutes, turning once",
      "Add the onions and garlic for a couple of minutes on each side",
    ],
    notes: [
      "Serves 4",
      "To eat, dip a piece of pork belly into the seasoned sesame seed oil, then place on a lettuce leaf with some rice, a slice of garlic, onion and a little of the sauce. Wrap it up and eat!"
    ],
    cuisineType: recipePath.korean,
  },
  {
    id: 28,
    img: "eggCurry.jpg",
    title: "Egg Curry",
    original: "This recipe is taken from the recipe book Jamie Oliver Veg which can be found ",
    originallink: "https://www.amazon.co.uk/Veg-Easy-Delicious-Meals-Everyone/dp/0718187768/ref=sr_1_1?crid=3BEO2XSEP3SSQ&keywords=jamie+oliver+veg&qid=1655596828&sprefix=jamie+olver+veg%2Caps%2C78&sr=8-1",
    description: "Easy vegetarian curry dish!",
    ingredients: [
      "Onions - 2",
      "Cloves of garlic - 2",
      "Ginger - 2cm",
      "Red chillies - 2",
      "Olive oil",
      "Korma paste - 2 tbsp",
      "Eggs - 2 or 3 each",
      "Plum tomatoes - 1 tin (400g)",
      "Coconut milk - 1 tin (400g)",
      "Butter beans (or chickpeas) - 1 tin",
      "Self raising flour - 200g",
      "Yoghurt - 150g",
      "Coriander - 1/2 bunch",
      "Lemon - 1",
    ],
    instructions: [
      "Finely slice the onions, ginger, garlic, and one of the chillies",
      "Heat 1 tablespoon of oil in a large casserole pan on a high heat",
      "Fry for 10 minutes, or until soft, stirring constantly",
      "Stir in the paste and a good pinch of salt, then cook for a further 5 minutes until sticky and caremelized, stirring regularly",
      "Meanwhile, cook the eggs in a pan of boiling water on a high heat for 7 minutes, then drain and cool under col running water and peel",
      "Scrunch the tomatoes into the pan, and pour in the coconut milk",
      "Simmer on a low heat for 10 minutes, stirring occasionally, halving and adding the eggs for the last 5 minutes",
      "Meanwhile, put the flour, yoghurt, 2 tablespoons of oil, and a pinch of salt into a bowl, then mix, bring together and put into a dough",
      "Halve, then roll out each piece on a flour-dusted surface until just under 1cm thick",
      "One at a time, cook in a large non-stick pan on a medium heat for 3 minutes, or until golden and cooked through, turning halfway",
      "Pick and finely chop the corinader leaves, stir into the curry, then season to taste",
      "Tear and divide up the flatbreads and top with a spoon of curry",
      "Finely slice and scatter the remaining chilli, and slice the lemon into wedges for squeezing over. Serve with a dollop of yoghurt, if you like",
      "",
    ],
    notes: [
      "You can use rice instead of the bread, if you like",
      "The recipe has butter beans/chickpeas has optional, but we feel like they are necessary for this dish",
    ],
    cuisineType: recipePath.indian,
  },
  {
    id: 29,
    img: "bangBangNoodles.jpg",
    title: "Sichuan Bang Bang Chicken Noodles",
    original: "This recipe is taken from Marion's Kitchen YouTube channel which can be found ",
    originallink: "https://www.marionskitchen.com/sichuan-bang-bang-chicken-noodles/",
    description: "Excellent dish to share with friends and family!",
    ingredients: [
      "2 x 200g (7 oz) chicken breasts",
      "4 slices ginger, bruised",
      "3 garlic cloves, bruised",
      "270g dried udon noodles (or any other noodles of your choice)",
      "1 large cucumber, finely sliced",
      "1 tsp whole Sichuan peppercorns",
      "2 tbsp toasted sesame seeds",
      "4 tbsp soy sauce",
      "1 tbsp vinegar (either white vinegar or Chinese black vinegar)",
      "2 tbsp chilli oil",
      "1 tbsp peanut butter",
      "1 tsp chilli powder",
    ],
    instructions: [
      "Place 4 cups of water in medium saucepan. Add the ginger, garlic and spring onions. Add the chicken breasts and place over high heat. Wait for the water to just start to gently simmer, then turn the heat down to medium and continue to simmer for 8 minutes.",
      "Then turn the heat off (leave the saucepan on the hot stovetop) and leave for 10 minutes. Transfer the chicken to a bowl of iced water. Reserve ½ cup of the poaching liquid.",
      "When the chicken is cool, use your hands to shred the chicken",
      "For the dressing, heat the Sichuan peppercorns in a dry frying pan until fragrant. Use a mortar and pestle to grind to a fine powder. Transfer to a bowl. Add the toasted sesame seeds, soy sauce, vinegar, chilli oil, peanut butter, chilli powder and reserved poaching liquid. Whisk until smooth.",
      "Cook the noodles according to packet instructions. Drain and divide among serving bowls. Top with the chicken and the cucumber. Spoon over generous spoonfuls of the sauce and serve.",
    ],
    notes: [
      "You can use rice instead of the bread, if you like",
      "The recipe has butter beans/chickpeas has optional, but we feel like they are necessary for this dish",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=jStv-SBbHLU'",
  },
  {
    id: 30,
    img: "shrimpFriedRice.jpg",
    title: "Easy Shrimp Fried Rice",
    original: "This recipe is taken from Souped Up Recipes YouTube channel which can be found ",
    originallink: "https://soupeduprecipes.com/easy-shrimp-fried-rice-recipe/",
    description: "Quick and easy with minimal ingredients",
    ingredients: [
      "2 cups (330g) of cooked rice",
      "2 whole eggs",
      "1 tbsp of soy sauce",
      "1 tbsp of oyster sauce",
      "1 tsp of dark soy sauce",
      "1 tbsp of Sambal chili sauce, optional",
      "3 tbsp of oil to stir fry",
      "1 tbsp of minced garlic",
      "1/4 cup of diced onion",
      "2/3 cups (80g) of diced vegetables",
      "1 cup (150g) of shrimp",
      "1/4 tsp of salt",
      "White pepper to taste",
      "A drizzle of oil to marinade the shrimp",
    ],
    instructions: [
      "Season the shrimp with some salt, white pepper, and a drizzle of oil. Mix well and set it aside.",
      "Season the rice with soy sauce, oyster sauce, dark soy sauce, and Sambal chili sauce. The benefit of seasoning the rice before cooking is that you can take your time to get everything ready to avoid rummaging around when the heat is on.",
      "Crack 2 eggs into the rice and mix well.",
      "Turn the heat to high and heat the wok until smoking hot. Drizzle in some cooking oil, and swirl it around. Add the shrimp. Stir for just a minute. Once the shrimp change color, add the diced onion and garlic. Keep stirring until fragrant.",
      "Add the diced vegetables and stir for a few minutes or until cooked through.",
      "Remove the shrimp & vegetables from the wok. Drizzle more oil into the wok and add the rice. Stir over medium heat for a few minutes. Don’t use high heat otherwise, you will burn the egg. The rice will be gluey in the beginning. As the egg starts solidifying, the grains will separate naturally.",
      "When the rice becomes fluffy, introduce the shrimp and the vegetables back to the wok. Sprinkle some diced scallions and sesame seeds for extra flavor. Give it a final toss and serve",
    ],
    notes: [
      "You can replace the shrimp with anything you like, or nothing at all",
      "Unlike most fried rice recipes where you need day-old rice, with this one you can use freshly cooked rice",
    ],
    cuisineType: recipePath.chinese,
    youtube: "'https://www.youtube.com/watch?v=OfifS6BhSG0'",
  },
  {
    id: 31,
    img: "mangoChickenCurry.jpg",
    title: "Mango Chicken Curry",
    original: "This recipe is taken from the recipe book The Curry Guy Easy which can be found ",
    originallink: "https://www.amazon.co.uk/Curry-Guy-Easy-Dan-Toombs/dp/1787131289/ref=sr_1_1?dchild=1&keywords=the+curry+guy+easy&qid=1602886487&sr=8-1",
    description: "A little different from your typical curry with the addition of mango but tastes amazing!",
    ingredients: [
      "Base curry sauce - 600ml (2.5 cups)",
      "Rapeseed oil - 2 tbsp",
      "Mixed garlic and ginger paste - 2 tbsp",
      "Curry powder - 1 tbsp",
      "Red chilli powder - 1 tsp (or to taste)",
      "Coriander stalks (finely chopped)  - 3 tbsp",
      "Green chillies (thinly sliced) - 2",
      "Coconut flour - 6 tbsp",
      "Smooth mango chutney - 4 tbsp",
      "Pre cooked chicken chicken - 700g ",
      "Small mango (cut into chunks) - 1",
      "Garam masala - 1 tsp",
      "Dried methi leaves - 1 tsp",
      "Coriander leaves (finely chopped) - 3 tbsp",
      "Salt",
    ],
    instructions: [
      "Heat the oil over a medium high heat. When hot, stir in the garlic and ginger paste and let it fry for 30 seconds.",
      "Stir in the curry powder, chilli powder, chopped coriander stalks, and fresh chillies.",
      "Stir really well and add 250ml (1 cup) of the base curry sauce.",
      "Let it come to a simmer, only stirring if it starts sticking to the pan.",
      "Add the coconut flour, mango chutney, and another 125ml (0.5 cup) of the base curry sauce.",
      "Add the chicken and heat (or cook) through.",
      "Add the mango chunks and the rest of the base curry sauce and let cook for another 4 minutes or until it is the consistency you prefer. You can add more base curry sauce or water if needed.",
      "To finish, season with salt, and sprinkle the garam masala and dried methi leaves over the stop. Stir it up and garnish with the fresh coriander leaves.",
    ],
    notes: [
      "The recipe calls for pre-cooked chicken, for example left over from a roast dinner, but we usually use fresh chicken and cook during this recipe",
      "Highly recommend this book if you love Indian cuisine!"
    ],
    cuisineType: recipePath.indian,
  },
  {
  id: 32,
  img: "chickenAndEggNoodles.jpg",
  title: "Thai Chicken & Egg Noodles (Guay Tiew Khua Gai)",
  original: "This recipe is taken from Marion's Kitchen YouTube channel which can be found ",
  originallink: "https://www.marionskitchen.com/thai-style-salmon-salad/",
  description: "Great thai dish that's a little bit different",
  ingredients: [
    "300g chicken thighs, cut into thin slices",
    "2 tbsp soy sauce",
    "2 tbsp oyster sauce",
    "¼ tsp white pepper",
    "3 green lettuce leaves, roughly chopped",
    "2 tbsp vegetable oil, plus extra to drizzle while cooking",
    "350g (12.3 oz) fresh rice noodles",
    "2 eggs, lightly whisked",
    "1 tsp sesame oil",
    "2 tbsp finely chopped spring onion",
    "extra soy sauce and chilli powder, to serve",
    "1 tbsp Thai pickled sweet radish* (optional)",
  ],
  instructions: [
    "Combine the chicken with the soy sauce, oyster sauce and white pepper. Mix well.",
    "Prepare individual serving bowls by lining them with lettuce leaves.",
    "Heat the vegetable oil in a wok or large frying pan over high heat. Add the chicken and the marinade and stir-fry for 2-3 minutes or until the chicken is almost cooked.",
    "Add the noodles and stir-fry for about 10 seconds. Then let them rest for 10 seconds to warm through. Now toss them around to break them up and get them mixing with the chicken and marinade.",
    "Then spread them out in the pan and allow them to cook for 30 seconds or until starting to char at the edges.",
    "Toss the noodles around (try to do this in chunks rather than breaking them up too much). Then allow them to settle, drizzle a little oil over them and allow them to cook undisturbed again for another 30 seconds.",
    "By this stage there should be dark brown and almost burned spots of noodles. Repeat this one more time. Now pour the egg over the noodles. Spread the egg out and allow it to cook for 10 seconds.",
    "Sprinkle over the pickled radish if using. Use your spatula to divide the noodles into 2 portions. Flip each portion separately.",
    "The pour over the sesame oil. When the egg is cooked, scoop chunks of the noodles onto the lettuce leaves. Sprinkle with spring onion.",
    "Serve with extra soy sauce and chilli powder.",
  ],
  notes: [
    "You can also use dried rice noodles, you just have to prepare them according to the packet in advance.",
  ],
  cuisineType: recipePath.thai,
  youtube: "'https://www.youtube.com/watch?v=qxH3PQ94BjM'",
},
];
