import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

export default function HeroSection() {
  return (
    <div className="hero-container">
      {/*commenting out nlow line as using image instead of video now*/}
      {/*<video src="/videos/video-2.mp4" autoPlay loop muted />*/}
      <h1>RECIPES AND TRAVEL</h1>
      <p>What are you waiting for?</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          linkTo="/recipes"
        >
          RECIPES
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          linkTo="/travel"
        >
          TRAVEL
        </Button>
      </div>
    </div>
  );
}
