import React from "react";
import CardItem from "../CardItem";
import "./General.css";
import {recipePath} from "./RecipeData" 

export function Recipes() {
  var cuisines = [
    {
      image: "images/chinese.jpg",
      text: "Chinese",
      path: recipePath.chinese,
    },
    {
      image: "images/japanese.jpg",
      text: "Japanese",
      path: recipePath.japanese,
    },
    {
      image: "images/indian.jpg",
      text: "Indian",
      path: recipePath.indian,
    },
    {
      image: "images/koreanBipimbap.jpg",
      text: "Korean",
      path: recipePath.korean,
    },
    {
      image: "images/thaiSalad.jpg",
      text: "Thai",
      path: recipePath.thai,
    },
  ];
  function generateCuisines() {
    return cuisines.map((cuisine) => {
      return (
        <CardItem
          key={cuisine.text}
          src={"../../" + cuisine.image}
          text={cuisine.text}
          path={"/recipes/" + cuisine.path}
          label="Cuisine"
        ></CardItem>
      );
    });
  }

  return (
    <div className="overall__page">
      <div className="container">
        <div className="wrapper">
          <ul className="button">{generateCuisines()}</ul>
        </div>
      </div>
    </div>
  );
}

export default Recipes;
