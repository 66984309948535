import { useLocation } from 'react-router-dom';

export function ScrollToTop({ history }) {
  var lastLocation ="last_location"
    var loc = useLocation()
   if(lastLocation !== loc.pathname) {
       lastLocation = loc.pathname
       window.scrollTo(0,0)
    } 
  console.log(loc)
  return null
}
