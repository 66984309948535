import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Pages/Home";
import Recipes from "./components/Pages/Recipes";
import Travel from "./components/Pages/Travel";
import SignUp from "./components/Pages/SignUp";
import { RecipesBasedOnCuisine } from "./components/Pages/RecipesBasedOnCuisine";
import { recipeNames } from "./components/Pages/RecipeNames"
import { TravelBasedOnLocation } from "./components/Pages/TravelBasedOnLocation";
import { travelNames } from "./components/Pages/TravelNames";
import {ScrollToTop} from "./generic/scroll-to-top"


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop/>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/recipes/:cuisinetype"
            component={RecipesBasedOnCuisine}
          />
          <Route
            path="/recipe-name/:name"
            component={recipeNames}
          />
          <Route
            path="/travel/:locationtype"
            component={TravelBasedOnLocation}
          />
          <Route
            path="/location-name/:name"
            component={travelNames} 
          />
          <Route path="/recipes" component={Recipes} />
          <Route path="/travel" component={Travel} />
          <Route path="/sign-up" component={SignUp} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
