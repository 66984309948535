import React from "react";
import CardItem from "../CardItem";
import "./General.css";
import {recipedata} from "./RecipeData";

export function RecipesBasedOnCuisine(historyParams) {
  console.log(historyParams);
  const cuisine = historyParams.match.params.cuisinetype;
  
  const filteredRecipes = recipedata.filter(
    (recipe) => recipe.cuisineType === cuisine
  );
  return (
    <div className="overall__page">
      <div className="container">
        <div className="wrapper">
          <ul className="button">
            {filteredRecipes.length < 1 ? (
              <div>no recipes found</div>
            ) : (
              filteredRecipes.map((recipe) => (
                <CardItem
                  key={recipe.id}
                  src={"../../images/" + recipe.img}
                  text={recipe.title}
                  path={"/recipe-name/" + recipe.id}
                  label="Recipe"
                >  
                </CardItem>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
