import React from "react";
import ReactPlayer from "react-player";

import "./General.css";
import { recipedata } from "./RecipeData";
import "./RecipeNames.css"


export function recipeNames(names) {
  const recipetitle = names.match.params.name;

  const uniquerecipe = recipedata
    .filter((onerecipe) => {
      return onerecipe.id.toString() === recipetitle;
    })
    .pop();

  return (
    <div className="above">
  <h1>{uniquerecipe.title}</h1>
  <h3>{uniquerecipe.original}<a href={uniquerecipe.originallink}>here</a>.</h3>
  <h3>{uniquerecipe.description}</h3>
    <div className="recipes-css">
  <h2>Ingredients</h2>
  <ul>
  {uniquerecipe.ingredients.map((ingredient) => <li>{ingredient}</li>)}
  </ul>
  <h2>Instructions</h2>
  <ol>
  {uniquerecipe.instructions.map((instruction) => <li>{instruction}</li>)}
  </ol>
  <h2>Notes</h2>
  <ul>
  {uniquerecipe.notes.map((note) => <li>{note}</li>)}
  </ul>
 {/*<div>{uniquerecipe.recipe}</div>
     <img className="foto" alt={uniquerecipe.title} src={"../../images/" + uniquerecipe.img} />
  </div>*/}
    </div>
  <div className="youtubevid">
  <ReactPlayer url={uniquerecipe.youtube}></ReactPlayer>
</div>
</div>
  );
}
