import React from "react";
import CardItem from "../CardItem";
import "./General.css";
import { locationPath } from "./TravelData";

export function Travel() {
  var locations = [
    {
      image: "images/iceland.jpg",
      text: "Iceland",
      path: `location-name/${locationPath.iceland}`,
    },
    {
      image: "images/greece.jpg",
      text: "Greece",
      path: `/location-name/${locationPath.greece}`,
    },
    {
      image: "images/germany.jpg",
      text: "Germany",
      path: `/location-name/${locationPath.germany}`,
    },
  ];
  function generateLocations() {
    return locations.map((location) => {
      return (
        <CardItem
          key={location.text}
          src={"../../" + location.image}
          text={location.text}
          path={location.path}
          label="Blog"
        ></CardItem>
      );
    });
  }

  return (
    <div className="overall__page">
      <div className="container">
        <div className="wrapper">
          <ul className="button">{generateLocations()}</ul>
        </div>
      </div>
    </div>
  );
}

export default Travel;
