import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="cards">
      <h1>Check out these amazing recipes and destinations!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/iceland.jpg"
              text="See all Iceland has to offer"
              label="Road Trip"
              path="/travel"
            />
            <CardItem
              src="images/greece.jpg"
              text="Sun, Sea and Sand in Greece"
              label="Relax"
              path="/travel"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/koreanPorkBelly.jpg"
              text="Korean Recipes"
              label="Korean"
              path="/recipes/korean-recipes"
            />
            <CardItem
              src="images/japanese.jpg"
              text="Japanese Recipes"
              label="Japanese"
              path="/recipes/japanese-recipes"
            />
            <CardItem
              src="images/chinese.jpg"
              text="Chinese Recipes"
              label="Chinese"
              path="/recipes/chinese-recipe"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
